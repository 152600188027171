<template>
  <div class="forgotten-password-dialog">
    <h2
      class="text-center text-body-1 font-weight-light forgotten-password-dialog__subtitle"
    >
      {{ $trans("forgotten_password_text") }}
    </h2>
    <forgotten-password-dialog-form
      class="forgotten-password-dialog__form"
      @loading="$emit('loading', $event)"
    />
  </div>
</template>

<script>
export default {
  name: "ForgottenPasswordDialog",
  components: {
    ForgottenPasswordDialogForm: () => import("./ForgottenPasswordDialogForm"),
  },
};
</script>

<style lang="scss" socped>
.forgotten-password-dialog__subtitle {
  padding: 0 10px;
}

.forgotten-password-dialog__form {
  padding: 0 10px;
}

@media (min-width: 768px) {
  .forgotten-password-dialog__subtitle {
    margin-bottom: 10px;
    padding: 0 65px;
  }

  .forgotten-password-dialog__form {
    padding: 0 65px;
  }
}
</style>
